.MuiSvgIcon-root {
  color: #AABCCC;
}

input::placeholder {
  opacity: 1;
  color: #AABCCC;
}
.vert-separator{
  width: 1px;
  height: 95%;
  background: var(--border-color);
}
#manager-notifications-tab {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: space-between;

  .opening-hours-section{
    height: 55%;
    width: 100%;
    display: flex;
  }
  .contact-section{
    height: 54%;
  }

  .title-s {
    font-size: 1rem;
    font-weight: 500;
    padding-left: 17px;
    color: #EAFBFF;
    line-height: 2rem;
  }
}