body {
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale;  /* Firefox */
  -ms-font-smoothing: antialiased;     /* Older Edge */
  font-family: "Work Sans", serif !important;

}
html, body, #root {
  font-family: "Work Sans", serif !important;
  height: 100%;
  max-width: 100%;
  overscroll-behavior: none;
  -ms-scroll-limit: none; /* Older Edge */
  cursor: default;
  background: #F7F8FC;
}
div, span, button, input, p {
  font-family: "Work Sans", serif !important;
}
.MuiTableRow-root{
  font-family: "Work Sans", serif !important;
}
.MuiButtonBase-root{
  font-family: "Work Sans", serif !important;
}
.MuiTableCell-root{
  font-family: "Work Sans", serif !important;

}
.MuiTypography-root{
  font-family: "Work Sans", serif !important;
}
body::-ms-scrollbar {
  display: none; /* for Edge and IE */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
