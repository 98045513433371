
#toggle-board {
  min-width: 280px;
  margin: 0 auto;
  height: fit-content;
  max-height: 100%;
  padding-left: 0px;


  .toggle-board-container {
    background-color: var(--primary-bg);
    color: var(--primary-text);
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 500px;

    .toggle-board-title {
      color: var(--title-color);
      padding: 0;
      font-weight: 500;
      font-size: 1rem;
      width: calc(100% - 15px);
      margin-bottom: 16px;
    }

    .toggle-board-scrollable {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      overflow-y: auto;


    }

    .toggle-board-item {
      margin-bottom: 10px;
    }

    .toggle-board-label {
      font-size: 1rem;
      color: var(--primary-text);
      padding-left: 15px;
    }

    .switch {
      padding-left: 0px;
      margin: -6px 0 0 15px;
      width: 45px;

      .MuiSwitch-thumb {
        background-color: #fff;
        margin: 4px 0 0 2px;
      }

      .MuiSwitch-switchBase {
        left: -10px;
      }

      .MuiSwitch-track {
        background-color: #9DADBC;
        opacity: 1;
        height: 22px;
        border-radius: 30px;
      }

      .Mui-checked {
        left: -19px;
      }

      .Mui-checked + .MuiSwitch-track {
        background-color: #57A6DB;
        opacity: 1;
      }
    }
  }
}