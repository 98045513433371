* { padding: 0; margin: 0; }
@font-face {
  font-family: 'Work Sans';
  src: local('./public/fonts/WorkSans-Regular.woff'), url('./public/fonts/WorkSans-Regular.woff') format('woff');
}

#root{
  height: 100%;
  overflow: hidden;
  font-family: "Work Sans", serif !important;
}
.padding15{
  padding:15px;
}
.padding30{
  padding:30px;
}
:root {
  --primary-bg: #001333;
  --primary-text: #9DADBC;
  --border-color: #3D5484;
  --border-color-dis: #4e5b77;
  --title-color: #EAFBFF;
  --switch-thumb: #FFFFFF;
  --switch-active: #57A6DB;
}